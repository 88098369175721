<template>
    <div class="page-wrapper">
        <div class="main-title-wrap" hero-transition-group>
            <img
                src="@/assets/img/pastille.svg"
                class="pastille"
                alt="Houblon Mellön"
                data-inview="fadeInUp"
                data-delay="100"
            />

            <h1 class="title special nav-btns" data-inview="fadeInUp" data-delay="200">
                <router-link :to="{ name: 'Bieres-' + $route.meta.lang }" :class="{ 'is-active': !section || section && section === 'fut' }">{{ $t('En fût') }}</router-link>

                <router-link :to="{ name: 'Bieres-' + $route.meta.lang, query: { section: 'emporter' } }" :class="{ 'is-active': section && section === 'emporter' }">{{ $t('Pour emporter') }}</router-link>
            </h1>

            <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="scale"
                data-delay="200"
            >
                <path
                    d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                    fill="#BEA851"
                />
            </svg>
            <h2 class="title" data-inview="fadeInUp" data-delay="300" >
                <span v-html="contentIsLoaded ? content.introSimple1.titre : '&nbsp;'"></span>
            </h2>
            <!-- <h2 class="title" data-inview="fadeInUp" data-delay="300" v-if="section === 'bouffe'">
                <span v-html="contentIsLoaded ? content.titreDuMenuBouffe : '&nbsp;'"></span>
            </h2> -->
        </div>

        <!-- <transition name="page" mode="out-in"> -->
        <div hero-transition-group>

            <section class="menu-bieres" v-if="!section || section && section === 'fut'">

                <div class="beers-list" hero-transition-group>
                    <div class="title-wrap" data-inview="fadeInUp" data-delay="100">
                        <p class="title">{{ $t('Actuellement disponible') }}</p>
                    </div>

                    <router-link
                        :to="item.page.jsonUrl"
                        class="beer-wrap"
                        data-inview="fadeInUp"
                        :data-delay="1 * i + '00'"
                        v-for="(item, i) in listeBieres" :key="item.page.id"
                    >
                        <div class="img">
                            <div class="tag" v-if="item.page.nouveaute"><p class="text">{{ $t('Nouveauté') }}</p></div>
                            <div class="types">
                                <div v-if="item.page.surPlace" class="type-tag">{{ $t('Sur place') }}</div>
                                <div v-if="item.page.aEmporter" class="type-tag">{{ $t('À emporter') }}</div>
                            </div>
                            <img :data-src="item.page.imageSimple.biere.url" class="main-img-beer lazyload" :alt="item.page.imageSimple.biere.titre" />
                        </div>
                        <div class="infos">
                            <div class="top">
                                <h4 class="title">{{ item.page.titre }}</h4>
                                <p class="text">{{ item.page.sorte }}</p>
                            </div>
                            <div class="bottom">
                                <div class="data">
                                    <p class="number">{{ item.page.ibu }}</p>
                                    <p class="text">IBU</p>
                                </div>
                                <div class="data">
                                    <p class="number">{{ item.page.alcVol }}</p>
                                    <p class="text">ALC<span>/VOL</span></p>
                                </div>
                                <div class="arrow">
                                    <svg
                                        width="25"
                                        height="17"
                                        viewBox="0 0 25 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M0 7.61401H23.6702V8.91004H0V7.61401Z" fill="#2E3E30" />
                                        <path
                                            d="M16.7226 0L25 8.31128L16.7226 16.59L15.7915 15.6588L23.1393 8.31101L15.7915 0.930929L16.7226 0Z"
                                            fill="#2E3E30"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </router-link>

                </div>

                <div class="cta-wrap">
                    <router-link :to="{ name: 'BieresArchive-' + $route.meta.lang }" class="cta" data-inview="scale" data-delay="100">
                        <p class="text">{{ $t('Voir nos anciennes créations') }}</p>
                    </router-link>
                </div>

            </section>

            <section class="menu-bieres" v-else>

                <div class="beers-list" hero-transition-group>
                    <div class="title-wrap" data-inview="fadeInUp" data-delay="100">
                        <p class="title">{{ $t('Actuellement disponible') }}</p>
                    </div>

                    <router-link
                        :to="item.page.jsonUrl"
                        class="beer-wrap"
                        data-inview="fadeInUp"
                        :data-delay="1 * i + '00'"
                        v-for="(item, i) in listeBieresEmporter" :key="item.page.id"
                    >
                        <div class="img">
                            <div class="tag" v-if="item.page.nouveaute"><p class="text">{{ $t('Nouveauté') }}</p></div>
                            <div class="types">
                                <div v-if="item.page.surPlace" class="type-tag">{{ $t('Sur place') }}</div>
                                <div v-if="item.page.aEmporter" class="type-tag">{{ $t('À emporter') }}</div>
                            </div>
                            <img :data-src="item.page.imageSimple.biere.url" class="main-img-beer lazyload" :alt="item.page.imageSimple.biere.titre" />
                        </div>
                        <div class="infos">
                            <div class="top">
                                <h4 class="title">{{ item.page.titre }}</h4>
                                <p class="text">{{ item.page.sorte }}</p>
                            </div>
                            <div class="bottom">
                                <div class="data">
                                    <p class="number">{{ item.page.ibu }}</p>
                                    <p class="text">IBU</p>
                                </div>
                                <div class="data">
                                    <p class="number">{{ item.page.alcVol }}</p>
                                    <p class="text">ALC<span>/VOL</span></p>
                                </div>
                                <div class="arrow">
                                    <svg
                                        width="25"
                                        height="17"
                                        viewBox="0 0 25 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M0 7.61401H23.6702V8.91004H0V7.61401Z" fill="#2E3E30" />
                                        <path
                                            d="M16.7226 0L25 8.31128L16.7226 16.59L15.7915 15.6588L23.1393 8.31101L15.7915 0.930929L16.7226 0Z"
                                            fill="#2E3E30"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </router-link>

                </div>

                <div class="cta-wrap">
                    <router-link :to="{ name: 'BieresArchive-' + $route.meta.lang }" class="cta" data-inview="scale" data-delay="100">
                        <p class="text">{{ $t('Voir nos anciennes créations') }}</p>
                    </router-link>
                </div>

            </section>

        </div>
        <!-- </transition> -->
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'BieresIndex',

    data() {
        return {
            section: 'fut'
        }
    },

    components: {},

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    this.section = this.$route.query.section

                    if (this.section === 'bouffe') {
                        // Redirect to new page /bouffe
                        this.$router.push({ name: 'Bouffe-' + this.$route.meta.lang })
                    }

                    setTimeout(() => {
                        initInViewAnimations()
                    }, 1)
                }
            },
            immediate: true,
        },
    },

    computed: {
        listeBieres() {
            if (!this.pageIsLoaded) {
                return []
            }

            let results = this.globals.biereEntry.data.filter(b => b.page.surPlace)

            results = results.filter(b => !b.page.anciennementEnFut)

            return results
        },
        listeBieresEmporter() {
            if (!this.pageIsLoaded) {
                return []
            }

            let results = this.globals.biereEntry.data.filter(b => b.page.aEmporter)

            results = results.filter(b => !b.page.anciennementEnFut)

            return results
        },
        listeCategoriesMenu() {
            if (!this.pageIsLoaded) {
                return []
            }

            const categories = []

            this.globals.menuEntry.data.forEach(item => {
                if (!categories.includes(item.page.categorieJour)) {
                    categories.push(item.page.categorieJour)
                }
            })

            return categories
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        menuItemsForCategory(category) {
            if (!this.pageIsLoaded) {
                return []
            }

            return this.globals.menuEntry.data.filter(item => item.page.categorieJour === category)
        },
    },
}
</script>
